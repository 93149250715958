<!--
 * @Description:意见反馈-我要反馈 myFeedBack
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-24 12:03:16
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="反馈建议"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-form @submit="onSubmit"
              class="form">

      <!-- 反馈类型 选择器 -->
      <van-row class="feedbackType">反馈类型</van-row>
      <van-row class="feedbackType-choose">
        <van-field readonly
                   clickable
                   name="picker"
                   :model="form.feedbackType"
                   :value="value"
                   placeholder="请选择"
                   @click="showPicker = true" />
        <van-popup v-model="showPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="feedbackType"
                      @confirm="onConfirm"
                      @cancel="showPicker = false" />
        </van-popup>
      </van-row>
      <!-- 问题描述 -->
      <van-row class="describeTitle">描述</van-row>
      <van-row class="contactWay-input">
        <van-cell-group>
          <van-field v-model="form.content"
                     type="textarea"
                     style="height:140px"
                     placeholder="请说明问题描述与建议，我们将为您不断改进" />
        </van-cell-group>
      </van-row>
      <!-- 上传图片 -->
      <van-row class="upLoad">
        <van-uploader upload-text='拍照/上传照片/视频'
                      v-model="fileList"
                      max-count="1"
                      accept="avi , flv , mpg , mpeg , mpe , m1v , m2v , mpv2 , mp2v , dat , ts , tp , tpr , pva , pss , mp4 , m4v , m4p , m4b , 3gp , 3gpp , 3g2 , 3gp2 , ogg , mov , qt , amr , rm , ram , rmvb , rpm"
                      :after-read='afterRead'
                      :before-read="beforeRead"
                      @oversize="onOversize"
                      preview-size='101' />
      </van-row>
      <van-row class="upLoadTip">支持格式：jpg.png.mp4,单个文件不能超过10MB</van-row>
      <van-row class="contactWay">联系方式</van-row>
      <van-row class="contactWay-input">
        <van-cell-group>
          <van-field v-model="form.concat"
                     placeholder="请输入手机号/邮箱/微信" />
        </van-cell-group>
      </van-row>
      <!-- 提交 -->
      <van-button class="button"
                  block
                  type="info"
                  native-type="submit">提交</van-button>
    </van-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { Toast, Dialog } from 'vant'
import getUserInfo from '@/utils/getUserInfo.js'
export default {
  components: {},
  data () {
    // 这里存放数据
    return {
      // 上传表单
      form: {
        concat: ''
      },
      // 反馈类型列表
      feedbackType: [],
      // 反馈类型编码
      FeedbackTypeCode: '',
      // 上传图片
      fileList: [],
      value: '', // 反馈类型

      // 选择弹出框
      showPicker: false

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(res => {
      // this.form.concat = res.phoneNumber
      this.getFeedbackType()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 表单提交
    onSubmit () {
      const info = {
        feedbackType: this.FeedbackTypeCode,
        content: this.form.content,
        picUrl: this.form.picUrl,
        concat: this.form.concat
      }
      if (!this.FeedbackTypeCode) {
        Toast('请选择反馈类型')
      } else if (!this.form.content) {
        Toast('请填写描述信息')
      } else if (!this.form.concat) {
        Toast('请输入联系方式')
      } else {
        Dialog.confirm({
          message: '确认提交'
        }).then(() => {
          // alert(JSON.stringify(info))
          this.$adviseFeedBack.addFeedBack(info).then(res => {
            Toast('反馈建议提交成功')
            this.$router.go(-1)
          }).catch(() => {
            Toast.fail('提交失败')
          })
        })
      }
    },
    // 上传前回调
    beforeRead (file) {
      if (file.size > 10000 * 1024 * 1024) {
        this.$toast.fail('大小超过限制')
        return false
      } else {
        return true
      }
    },
    // 上传 读取完成
    afterRead (file) {
      const that = this
      const formData = new FormData() // 为上传文件定义一个formData对象
      formData.append('file', file.file)
      // formData.append('id', this.form.picUrl)// 如需传递额外参数，例如id：this.form.id
      this.loading = true
      // $_uploadAction为请求方法，请使用自己项目中的axiox请求
      // 这里需要注意的是'Content-Type'必须为'application/form-data',
      this.$adviseFeedBack.upFile(formData).then(res => {
        if (res.resultEntity) {
          that.loading = false
          that.$toast.success('上传成功！')
          that.form.picUrl = res.resultEntity
        }
      }).catch(() => {
        that.$toast.fail('上传失败！')
      })
    },

    // 限制大小
    onOversize (file) {
      console.log(file)
      Toast('文件大小不能超过 10MB')
    },
    // 选择框确认
    onConfirm (value, index) {
      this.value = value
      this.showPicker = false
      this.FeedbackTypeCode = index + 1
    },
    // 获取反馈类型下拉框
    getFeedbackType () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '4324HJKH3F316FDG3LK4H1234L12K313'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        res.resultEntity.forEach(element => {
          this.feedbackType.push(element.name)
        })
      })
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 99vh; //vh 视口高度
  overflow-y: auto;
  overflow-x: hidden;
  .form {
    margin: 0 3.2%;
    // height: 100%;
    // width: 93.6%;
    // 反馈类型
    .feedbackType {
      margin-top: 10px;
      height: 23px;
      font-size: 15px;
      color: #333333;
      line-height: 23px;
    }
    // 反馈类型-选择
    .feedbackType-choose {
      height: 40px;
      margin-top: 10px;
    }
    //描述标题
    .describeTitle {
      margin-top: 15px;
      height: 23px;
      font-size: 15px;
      color: #333333;
      line-height: 23px;
    }

    .upLoad {
      margin-top: 25px;
      // background: red;
      height: 135px;
      // border: 1px solid #dddddd;
      // border-radius: 5px;
      /deep/ .van-uploader__upload {
        height: 135px !important;
        border-radius: 5px;
        border: 1px solid #dddddd;
      }
    }
    // 上传提示
    .upLoadTip {
      // width: 463px;
      height: 11px;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 23px;
    }
    // 联系方式
    .contactWay {
      margin-top: 19px;
    }
    .contactWay-input {
      margin-top: 11px;
    }
    /deep/ textarea {
      height: 120px !important;
    }
    .button {
      margin-top: 30px;
      border-radius: 5px;
    }
    /deep/.van-uploader__preview-image {
      height: 135px !important;
      border-radius: 5px;
    }
  }
}
</style>
